










































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import OverlayLoader from '@/components/OverlayLoader.vue';
import PredictionResults from './PredictionResults.vue';
import PredictionExplanations from './PredictionExplanations.vue';
import TextExplanation from './TextExplanation.vue';
import { api } from '@/api';
import { readToken } from '@/store/main/getters';
import { IFeedbackCreate } from '@/interfaces';
import FeedbackPopover from '@/components/FeedbackPopover.vue';
import Inspector from './Inspector.vue';

@Component({
  components: { OverlayLoader, Inspector, PredictionResults, FeedbackPopover, PredictionExplanations, TextExplanation }
})
export default class InspectorWrapper extends Vue {
	@Prop({ required: true }) modelId!: number
	@Prop({ required: true }) datasetId!: number
  @Prop() targetFeature!: string

  loadingData = false;
  inputData = {}
  originalData = {}
  rowNb: number = 0
  shuffleMode: boolean = false
  dataErrorMsg = ""

  feedbackPopupToggle = false
  feedback = ""
  feedbackChoice = null
  feedbackError = ""
  feedbackSubmitted = false

  async mounted() {
    await this.fetchRowData(0);
  }

  public next() {
    this.clearFeedback();
    this.fetchRowData(this.rowNb + 1);
  }
  public previous() {
    this.clearFeedback();
    this.fetchRowData(Math.max(0, this.rowNb - 1))
  }
  
  @Watch("datasetId")
  async reload() {
    await this.fetchRowData(0)
  }

  private async fetchRowData(rowId) {
    try {
      this.loadingData = true
      const resp = this.shuffleMode
        ? await api.getDataRandom(readToken(this.$store), this.datasetId)
        : await api.getDataByRowId(readToken(this.$store), this.datasetId, rowId) 
      this.inputData = resp.data
      this.originalData = {...this.inputData} // deep copy to avoid caching mechanisms
      this.rowNb = resp.data.rowNb
      this.dataErrorMsg = "";
    } catch (error) {
      this.dataErrorMsg = error.response.data.detail
    } finally {
      this.loadingData = false
    }
  }

  private resetInput() {
    this.inputData = {...this.originalData}
  }

  public clearFeedback() {
    this.feedback = ""
    this.feedbackError = ""
    this.feedbackSubmitted = false
    this.feedbackChoice = null
    this.feedbackPopupToggle = false;
  }

  get commonFeedbackData() {
    return {
      project_id: parseInt(this.$router.currentRoute.params.id),
      model_id: this.modelId,
      dataset_id: this.datasetId,
      target_feature: this.targetFeature,
      user_data: this.inputData,
      original_data: this.originalData
    }
  }

  public async submitGeneralFeedback() {
    const feedback: IFeedbackCreate = {
      ...this.commonFeedbackData,
      feedback_type: "general",
      feedback_choice: this.feedbackChoice || undefined,
      feedback_message: this.feedback 
    }
    try {
      await this.doSubmitFeedback(feedback)
      this.feedbackSubmitted = true
    } catch (err) {
      this.feedbackError = err.response.data.detail
    }
  }

  public async submitValueFeedback(userData: object) {
    const feedback: IFeedbackCreate = {
      ...this.commonFeedbackData,
      feedback_type: "value",
      ...userData
    }
    await this.doSubmitFeedback(feedback)
  }

  public async submitValueVariationFeedback(userData: object) {
    const feedback: IFeedbackCreate = {
      ...this.commonFeedbackData,
      feedback_type: "value perturbation",
      ...userData
    }
    await this.doSubmitFeedback(feedback)
  }

  private async doSubmitFeedback(payload: IFeedbackCreate) {
    await api.submitFeedback(readToken(this.$store), payload, payload.project_id)
  }

}
